<template>
    <div class="treatments container">
        <div>
            <div class="ui simple middle aligned computer only tablet only two column grid">
                <div class="column">
                    <h3 class="ui header">
                        Treatments
                        <div class="sub header">Manage treatments for prescription here</div>
                    </h3>
                </div>
                <div class="right aligned column">
                    <button class="ui black small button" type="button" @click="addTreatment"><i class="fontello-plus icon"></i> New Treatment</button>
                </div>
            </div>
            <div class="ui simple middle aligned mobile only grid">
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="!mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div>
                                    <h3 class="ui header">
                                        Treatments
                                        <div class="sub header">Manage treatments for prescription here</div>
                                    </h3>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="plus icon link" @click="addTreatment">
                                        <span>&plus;</span>
                                    </a>
                                    <a href="javascript:void(0);" class="icon link" @click="mobile_search = true;">
                                        <i class="search icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <transition name="vue-slide-up-fade">
                    <div class="one column row" v-show="mobile_search">
                        <div class="column">
                            <div class="ui simple flexbox">
                                <div style="flex: 1; min-width: 0;">
                                    <form class="ui search form" @submit.prevent="search_">
                                        <div class="ui icon fluid input" :class="{loading: loading}">
                                            <input type="text" placeholder="Code, description..." v-model.trim.lazy="query"/>
                                            <i class="search link icon"></i>
                                        </div>
                                    </form>
                                </div>
                                <div class="mobile action links">
                                    <a href="javascript:void(0);" class="close icon link" @click="mobile_search = false;">
                                        &times;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div>
            <div class="ui two column stackable simple grid">
                <div class="tablet only computer only column">
                    <form class="ui form" @submit.prevent="search_">
                        <div class="ui left icon action fluid input" :class="{loading: loading}">
                            <i class="search icon"></i>
                            <input type="text" placeholder="Code, description..." v-model.trim.lazy="query"/>
                            <button type="submit" class="ui icon black button">
                                <i class="arrow right icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="right aligned column">
                    <div class="ui compact secondary menu">
                        <div ref="sortbydropdown" class="ui inline dropdown item">
                            <input type="hidden" :value="sorting.by" @change="changeSorting"/>
                            <span style="margin-right: 0.5em;">Sort By:</span><div class="text">Default</div>
                            <i class="dropdown icon"></i>
                            <div class="menu">
                                <div class="item" data-value="code">Code</div>
                                <div class="item" data-value="description">Description</div>
                                <div class="item" data-value="active">Status</div>
                            </div>
                        </div>
                        <button class="ui fitted item button" type="button" title="Ascending" @click="sortAsc"><i class="sort amount up icon" :class="{teal: sorting.order === 'asc'}"></i></button>
                        <button class="ui fitted item button" type="button" title="Descending" @click="sortDesc"><i class="sort amount down icon" :class="{teal: sorting.order === 'desc'}"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-container">
            <div class="ui hidden divider"></div>
            <div class="ui very basic segment" :class="{loading}">
                <div class="ui secondary teal menu">
                    <a href="javascript:void(0);" class="item" :class="{active: !treatment_type}" @click="selectTreatmentType('')">All</a>
                    <a href="javascript:void(0);" class="item" :class="{active: treatment_type === treatment_type_key}" v-for="(treatment_type_description, treatment_type_key) in treatment_types" :key="`treatment_type_item_${treatment_type_key}`" @click="selectTreatmentType(treatment_type_key)">
                        {{treatment_type_description}}
                    </a>
                </div>
                <table class="ui very basic middle aligned unstackable responsive table">
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <template v-if="treatments.length">
                        <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                            <tr v-for="treatment in treatments" :key="`treatment_tr_${treatment.code}`">
                                <td data-title="Code" class="top aligned"><strong>{{treatment.code}}</strong><div><label class="ui tiny label">{{typeDescription(treatment.type)}}</label></div></td>
                                <td data-title="Description">
                                    {{treatment.description}}
                                </td>
                                <td data-title="Status">
                                    <toggle-button v-model="treatment.active" :width="toggle_checkbox.width" :height="toggle_checkbox.height" :color="toggle_checkbox.color" :labels="toggle_checkbox.labels" :tag="treatment.code" @change="toggleStatus" sync/>
                                </td>
                                <td class="right aligned">
                                    <div class="ui text compact action menu">
                                        <div class="ui right dropdown fitted item">
                                            <i class="ellipsis vertical teal icon"></i>
                                            <div class="menu">
                                                <a class="item" href="javascript:void(0);" @click="editTreatment(treatment)">Edit Treatment</a>
                                                <a class="item" href="javascript:void(0);" @click="deleteTreatment(treatment)"><span class="ui red text">Delete Treatment</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </transition-group>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td colspan="4">No treatment found.</td>
                        </tr>
                    </tbody>
                    <tfoot v-show="treatments.length">
                        <tr>
                            <th colspan="4" class="right aligned">
                                <pagination-menu ref="paginationmenu" :total-count="pagination.total_count" :start-page="pagination.page" :page-size="pagination.size" @page-changed="changePage" always-show/>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div ref="treatmentmodal" class="ui treatment modal">
            <div class="content">
                <h3 class="ui header">{{treatment_form.created? "Update Treatment" : "Create New Treatment"}}</h3>
                <div class="ui hidden divider"></div>
                <form :id="`treatmentform${_uid}`" class="ui form" :class="{loading: saving}" @submit.prevent="saveTreatment">
                    <div class="two fields">
                        <div class="required field">
                            <label>Code <em v-show="treatment_form.created" style="font-weight: 300; opacity: 0.8;">(Code cannot be edited)</em></label>
                            <input type="text" v-model.trim="treatment_form.code" :disabled="treatment_form.created" required v-uppercase/>
                        </div>
                        <div class="required field">
                            <label>Description</label>
                            <input type="text" v-model.trim="treatment_form.description" required/>
                        </div>
                    </div>

                    <div class="required field">
                        <label>Type</label>
                        <div class="fields" :class="treatment_type_length_text">
                            <div class="field" v-for="(treatment_type_description, treatment_type_key) in treatment_types" :key="`treatment_type_option_${treatment_type_key}`">
                                <div class="ui radio checkbox">
                                    <input type="radio" name="type" tabindex="0" class="hidden" :value="treatment_type_key" v-model="treatment_form.type" required>
                                    <label>{{treatment_type_description}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="actions">
                <button type="submit" :form="`treatmentform${_uid}`" class="ui submit teal button" :class="{loading: saving}" :disabled="saving">{{treatment_form.created? "Update" : "Submit"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";

import converter from "number-to-words";
import TreatmentType from "@/assets/constants/treatmentType";

import PaginationMenu from "@/components/PaginationMenu";
import { ToggleButton } from "vue-js-toggle-button";

export default {
    name: "treatments",
    components: {
        PaginationMenu,
        ToggleButton
    },
    data() {
        return {
            mobile_search: false,
            loading: true,
            saving: false,
            query: "",
            treatments: [],
            treatment_form: {},
            treatment_type: "",
            treatment_types: TreatmentType,
            sorting: {
                by: "created",
                order: "desc"
            },
            pagination: {
                total_count: 0,
                page: 1,
                size: 10
            },
            toggle_checkbox: {
                color: "#0cc2da",
                width: 36,
                height: 20,
                labels: {
                    checked: " ",
                    unchecked: " "
                }
            },
            opened_modals: [],
            history_popped: false,
            scrollY: window.scrollY || 0,
            search_: debounce(this.search, 100)
        };
    },
    created() {
        this.search_();
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$el).find(".ui.radio.checkbox").checkbox();
        $(this.$refs.sortbydropdown).dropdown();
        $(this.$refs.treatmentmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "treatmentmodal_opened");
                this.opened_modals.push("treatmentmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "treatmentmodal";
                });
            }
        });
    },
    activated() {
        this.search().finally(() => {
            if(this.scrollY) {
                this.$nextTick(() => {
                    window.scroll(0, this.scrollY);
                });
            };
        });
    },
    updated() {
        $(this.$el).find(".ui.action.menu:not(.disabled) .ui.dropdown").dropdown();
    },
    beforeRouteLeave(to, from, next) {
        this.scrollY = window.scrollY;
        next();
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    computed: {
        search_params() {
            let search_params = {
                page: this.pagination.page,
                size: this.pagination.size,
                sort: this.sorting.by,
                order: this.sorting.order
            };

            if(this.query) {
                search_params.search = JSON.stringify({
                    code: this.query,
                    description: this.query
                });
            }

            if(this.treatment_type) {
                search_params.filter = JSON.stringify({
                    type: this.treatment_type
                });
            }

            return search_params;
        },
        treatment_type_length_text() {
            let treatment_type_length_text = converter.toWords(1);
            if(Object.keys(this.treatment_types).length) {
                treatment_type_length_text = converter.toWords(Object.keys(this.treatment_types).length);
            }

            return treatment_type_length_text;
        }
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        addTreatment() {
            this.treatment_form = {};
            $(this.$refs.treatmentmodal).modal("show");
        },
        saveTreatment() {
            if(this.saving) {
                return;
            }

            this.saving = true;

            let treatment = this.treatment_form;
            if(treatment.created) {
                this.$http.put(`treatments/${treatment.code}`, treatment).then((response) => {
                    this.$toasted.success("Treatment updated successfully.", {
                        duration: 3000
                    });

                    this.search_();
                    $(this.$refs.treatmentmodal).modal("hide");
                }).catch((error) => {
                    console.error(error);

                    this.$toasted.error("Failed to update treatment.", {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            } else {
                this.$http.post("treatments", treatment).then((response) => {
                    this.$toasted.success("Treatment created successfully.", {
                        duration: 3000
                    });

                    this.search_();
                    $(this.$refs.treatmentmodal).modal("hide");
                }).catch((error) => {
                    console.error(error);

                    const status_code = error.response?.status || "";
                    const message = (status_code === 409)? "Treatment code already exists." : "Failed to create treatment.";

                    this.$toasted.error(message, {
                        duration: 3000
                    });
                }).finally(() => {
                    this.saving = false;
                });
            }
        },
        search() {
            this.loading = true;

            const params = this.search_params;
            return this.$http.get("treatments", {
                params
            }).then((response) => {
                this.treatments = response.data;

                const total_count = response.headers["x-total-count"];
                this.pagination.total_count = total_count? +total_count : this.treatments.length;
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to list treatments. Please try again later.", {
                    duration: 3000
                });
            }).finally(() => {
                this.loading = false;
            });
        },
        typeDescription(type) {
            return TreatmentType[type] || type;
        },
        changeSorting(event) {
            this.sorting.by = event.target.value;
        },
        sortAsc() {
            this.sorting.order = "asc";
        },
        sortDesc() {
            this.sorting.order = "desc";
        },
        changePage(page) {
            this.pagination.page = page;
        },
        selectTreatmentType(treatment_type) {
            this.treatment_type = treatment_type;
        },
        editTreatment(treatment) {
            this.treatment_form = Object.assign({}, treatment);
            $(this.$refs.treatmentmodal).modal("show");
        },
        deleteTreatment(treatment) {
            this.$confirm({
                title: "Delete Treatment",
                message: `Are you sure you want to delete treatment <strong>${treatment.description}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                callback: (confirmed) => {
                    if(confirmed) {
                        this.$http.delete(`treatments/${treatment.code}`).then((response) => {
                            this.$toasted.success(`Treatment ${treatment.description} deleted successfully.`, {
                                duration: 3000
                            });

                            this.search_();
                        }).catch((error) => {
                            console.error(error);

                            this.$toasted.error(`Failed to delete treatment ${treatment.description}.`, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        },
        toggleStatus({ srcEvent = {}, tag = "", value }) {
            if(tag) {
                const treatment = this.treatments.find((treatment) => {
                    return treatment.code === tag;
                });

                if(treatment) {
                    this.$confirm({
                        title: `${value? "Activate" : "Deactivate"} Treatment`,
                        message: `Are you sure you want to ${value? "activate" : "deactivate"} treatment <strong>${treatment.description}</strong>?`,
                        button: {
                            yes: "Yes",
                            no: "Cancel"
                        },
                        callback: (confirmed) => {
                            if(confirmed) {
                                this.$http.put(`treatments/${treatment.code}`, treatment).then((response) => {
                                    this.$toasted.success(`Treatment ${value? "activated" : "deactivated"} successfully.`, {
                                        duration: 3000
                                    });

                                    this.search_();
                                }).catch((error) => {
                                    treatment.active = !value;
                                    console.error(error);

                                    this.$toasted.error(`Failed to ${value? "activate" : "deactivate"} treatment.`, {
                                        duration: 3000
                                    });
                                });
                            } else {
                                treatment.active = !value;
                            }
                        }
                    });
                }
            }
        }
    },
    watch: {
        search_params: {
            deep: true,
            handler(search_params, _search_params) {
                if(search_params.search !== _search_params.search || search_params.filter !== _search_params.filter) {
                    if(this.pagination.page === 1) {
                        this.search_();
                    } else {
                        this.$refs.paginationmenu.stepTo(1);
                    }
                } else {
                    this.search_();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.treatments.container {
    background: #fff;
}

.ui.mobile.only.grid {
    position: relative;
}

.mobile.action.links {
    display: inline-flex;
    align-items: flex-start;

    .icon.link {
        font-size: 1.2rem;
        padding: 0.35rem;
        display: inline-block;

        &.plus, &.close {
            font-size: 2.25rem;
        }

        .icon {
            margin: 0;
        }
    }
}

.ui.action.menu {
    .ui.dropdown {
        .menu {
            > .active.item,
            > .selected.item {
                font-weight: 400 !important;
                color: rgba(0,0,0,.87)!important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        min-height: 0;
    }
}

@media only screen and (max-width: 767px) {
    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                td:first-child {
                    padding-right: 2em; //to give way for the action menu
                }

                .ui.action.menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .list-container {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-bottom: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fbf7f2;/*#fafff8;*/
        border-radius: 20px 20px 0 0;
    }
}
</style>